<template>
    <div>
        <b-card>
            <b-container
                class="mb-1"
                fluid
            >
                <b-row
                    class="mb-1"
                >
                    <b-col sm="2">
                        Full user's name:
                    </b-col>
                    <b-col sm="6">
                        <b-form-input
                            v-model="userData.name"
                            placeholder="User's name (ex. FirstName LastName) or (ex. FNLN)"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mb-1"
                >
                    <b-col sm="2">
                        <span>Full user's email: </span>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input
                            v-model="userData.email"
                            type="email"
                            placeholder="Email (ex. user@userEmail.com)"
                        />
                    </b-col>
                </b-row>
                <b-row
                    class="mb-1 text-right"
                >
                    <b-col
                        sm="8"
                    >
                        <b-button
                            variant="primary"
                            :disabled="saving"
                            @click="addUser"
                        >
                            <template v-if="saving">
                                <b-spinner small type="grow" />
                                <span class="sr-only">
                                    Saving...
                                </span>
                            </template>
                            <template v-else>
                                <span>
                                    Create user
                                </span>
                            </template>
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </div>
</template>

<script>
import Axios from 'axios'
import {
    BCard, BButton, BFormInput, BContainer, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    name: 'settings.users',
    components: {
        BCard,
        BButton,
        BFormInput,
        BContainer,
        BCol,
        BRow,
        BSpinner,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    data() {
        return {
            errors: [],
            userCreated: false,
            userData: {
                name: '',
                email: '',
            },
            saving: false,
        }
    },
    methods: {
        addUser() {
            this.saving = true
            this.userCreated = false
            this.errors = []
            Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}users/create`, data: this.userData, method: 'POST' })
                .then(() => {
                    this.saving = false
                    this.userCreated = true
                    this.userData.name = ''
                    this.userData.email = ''

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success!',
                            icon: 'CheckIcon',
                            text: 'User was created successfully and he should receive an confirmation email in just a moment.',
                            variant: 'primary',
                        },
                    })
                })
                .catch(err => {
                    for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                        const errorGroup = err.response.data.errors[errorGroupKey]
                        this.errors = this.errors.concat(errorGroup)
                    }
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error!',
                            icon: 'XIcon',
                            text: 'There was an error creating the user and it cannot be finalized at this moment.',
                            variant: 'primary',
                        },
                    })
                    this.saving = false
                })
        },
    },
}
</script>
